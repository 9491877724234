import * as React from 'react';
import Grid from '../soul/layout/grid/Grid';
import {GridDensity} from '../soul/layout/grid/GridDensity';
import {GridAlignment} from '../soul/layout/grid/GridAlignment';
import GridItem from '../soul/layout/grid/GridItem';
import InternalLink from '../soul/navigation/InternalLink';
import Img from 'gatsby-image';
import {SpeakerFrontmatter} from './SpeakersRow';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakerCardProps {
    relativePath: string,
    speaker: SpeakerFrontmatter
}

const SpeakerCard: React.FC<SpeakerCardProps> = ({relativePath, speaker}) => {
    const avatar = (frontmatter: SpeakerFrontmatter) => frontmatter.avatar.childImageSharp.fluid;
    const fullName = (frontmatter: SpeakerFrontmatter) => `${frontmatter.firstName} ${frontmatter.lastName}`;
    const speakerPageHref = (speakerPath: string) => `/${speakerPath.replace('.md', '')}/`;

    return (
        <InternalLink to={speakerPageHref(relativePath)}>
            <button className="m-button-card h-full-height">
                <Grid density={GridDensity.NARROW} alignment={GridAlignment.MIDDLE}>
                    <GridItem additionalClasses='l-one-whole l-palm--one-half'>
                        <div className="h-text-align-center">
                            <div className="s-avatar s-avatar--xl s-avatar--primary">
                                <Img className="s-avatar__image ew-um-avatar"
                                     fluid={avatar(speaker)}
                                     alt={fullName(speaker)}
                                />
                            </div>
                        </div>
                    </GridItem>

                    <GridItem additionalClasses='l-one-whole l-palm--one-half'>
                        <div className="soul-space-stack-top-xs">
                            <strong className="soul-font-size-m h-text-bold">{fullName(speaker)}</strong>
                        </div>
                        <span className="m-button-card__description">
                            <p className="soul-font-size-s a-label--reduced">{speaker.jobTitle}</p>
                            <p className="soul-font-size-xs h-text-bold">{speaker.organization}</p>
                        </span>
                    </GridItem>
                </Grid>
            </button>
        </InternalLink>
    );
};

export default SpeakerCard;
