import * as React from 'react';
import {GridDensity} from '../soul/layout/grid/GridDensity';
import GridItem from '../soul/layout/grid/GridItem';
import {GridItemSize} from '../soul/layout/grid/GridItemSize';
import SpeakerCard from './SpeakerCard';
import Grid from '../soul/layout/grid/Grid';
import {FluidObject} from 'gatsby-image';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakersRowProps {
    speakers: Speaker[];
    size: GridItemSize;
}

export interface Speaker {
    id: string;
    relativePath: string;
    childMarkdownRemark: {
        frontmatter: SpeakerFrontmatter
    };
}

export interface SpeakerFrontmatter {
    weight: number;
    firstName: string;
    lastName: string;
    avatar: {
        childImageSharp: {
            fluid: FluidObject
        }
    };
    jobTitle: string;
    organization: string;
    promoted: boolean;
}

const SpeakersRow: React.FC<SpeakersRowProps> = ({speakers, size}) => (
    <Grid density={GridDensity.NARROW} additionalClasses="h-responsive-row">
        {speakers.map(speaker => {
            return <GridItem key={speaker.id} size={size}
                             additionalClasses="l-lap--one-third l-palm--one-whole soul-space-stack-bottom-m">
                <SpeakerCard relativePath={speaker.relativePath} speaker={speaker.childMarkdownRemark.frontmatter}/>
            </GridItem>;
        })}
    </Grid>
);

export default SpeakersRow;
