import * as React from 'react';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import { graphql } from 'gatsby';
import SpeakersRow, { Speaker } from '../components/speaker/SpeakersRow';
import { GridItemSize } from '../components/soul/layout/grid/GridItemSize';
import SEO from '../components/SEO/SEO';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakersPageProps {
    data: {
        allFile: {
            nodes: Speaker[]
        }
    }
}

const SpeakersPage: React.FC<SpeakersPageProps> = ({data}) => {
    const lastName = (node: Speaker) => node.childMarkdownRemark.frontmatter.lastName;
    const sortedSpeakers = data.allFile.nodes.sort((a, b) => lastName(a).localeCompare(lastName(b)));
    return (
        <SingleColumnLayout>
            <SEO title="ESTECO UM20 | Meet Our Speakers"
                 description="Discover ESTECO UM20 speakers line-up. Join and learn how engineering professionals from industry and academia are facing the future of product development."/>
            <div className="centered-column">
                <h1 className="page-title">Meet our speakers</h1>

                <SpeakersRow speakers={sortedSpeakers} size={GridItemSize.ONE_FIFTH}/>
            </div>
        </SingleColumnLayout>
    );
};

export const pageQuery = graphql`
    query AllSpeakers {
        allFile(
            filter: {relativeDirectory: {eq: "speakers"}, extension: {eq: "md"}},
            sort: {fields: [childMarkdownRemark___frontmatter___lastName], order: [ASC]}
        ) {
            nodes {
                relativePath
                id
                childMarkdownRemark {
                    frontmatter {
                        weight
                        firstName
                        lastName
                        avatar {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        jobTitle
                        organization
                    }
                }
            }
        }
    }
`;


export default SpeakersPage;
